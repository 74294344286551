import React from 'react';
import styles from '../../styles/views/meetings/MeetingItems.scss';
import { __, setTableParams } from '../../core/utils';

// CONTEXT MENU
import { ContextMenu, MenuItem, SubMenu } from 'react-contextmenu';

// ICONS
import ArrowRightSmallIcon from '../../assets/images/arrow-right-small-40x40.svg';
import MoveIcon from '../../assets/images/move-16x16.svg';
import CloseMiddleIcon from '../../assets/images/close-middle-15x15.svg';

import Tooltip from '../../components/Tooltip';

function MeetingContextMenu({
  onDelete,
  onMove,
  id,
  onHide,
  insertActions,
  showOnlyInsertActions,
}) {
  const InsertActions = React.memo(() => {
    return _.map(insertActions, (button, i) => (
      <MenuItem key={i} onClick={button.onClick}>
        <Tooltip text={button.label}>
          <div className={styles.item}>
            <span>{button.label}</span>
          </div>
        </Tooltip>
      </MenuItem>
    ));
  });

  return (
    <ContextMenu
      id='meeting_items'
      className={styles.contextMenu}
      onHide={onHide}
    >
      {!showOnlyInsertActions ? (
        <>
          {/* --- MOVE --- */}
          <MenuItem onClick={onMove}>
            <Tooltip text={__('meeting.items.move-item')}>
              <div className={styles.item}>
                <span className={styles.icon}>
                  <MoveIcon />
                </span>
                <span>{__('meeting.items.move-item')}</span>
              </div>
            </Tooltip>
          </MenuItem>

          {/* --- DELETE --- */}
          <MenuItem onClick={() => onDelete(id)}>
            <Tooltip text={__('meeting.items.delete-item')}>
              <div className={styles.item}>
                <span className={styles.icon}>
                  <CloseMiddleIcon />
                </span>
                <span>{__('meeting.items.delete-item')}</span>
              </div>
            </Tooltip>
          </MenuItem>

          <MenuItem className={styles.itemDivider} divider />

          <SubMenu
            title={
              <div className={styles.item}>
                <span>{__('meeting-items.table.row.insert-below')}</span>

                <span className={styles.icon}>
                  <ArrowRightSmallIcon />
                </span>
              </div>
            }
          >
            {_.map(insertActions, (button, i) => (
              <MenuItem key={i} onClick={button.onClick}>
                <Tooltip text={button.label}>
                  <div className={styles.item}>
                    <span>{button.label}</span>
                  </div>
                </Tooltip>
              </MenuItem>
            ))}
          </SubMenu>
        </>
      ) : (
        <InsertActions />
      )}
    </ContextMenu>
  );
}

export default MeetingContextMenu;
