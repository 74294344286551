import * as React from 'react';
import { Component, useState, useEffect } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { listPossibleWorkSites } from '../../redux/actions/table/timesheets';
import styles from '../../styles/forms/edit_timesheet_wizard/EditTimesheetForm.scss';
import Button from '../../components/Button';
import ButtonGroup from '../../components/ButtonGroup';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import { connect } from 'react-redux';
import {
  __,
  getActiveProject,
  getActiveStage,
  resetForm,
  setFormValue,
} from '../../core/utils';
import Select from '../../components/Select';
import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import Checkbox from '../../components/Checkbox';
import autobind from 'autobind-decorator';
import DateTimePicker from '../../components/DateTimePicker';
import {
  listPossibleExistingMeetingTasks,
  listPossibleMeetingTasks,
} from '../../redux/actions/table/all_meetings';
import {
  listPossibleSpecificationProjects,
  listPossibleSpecificationStages,
  listPossibleSpecificationTeams,
} from '../../redux/actions/table/working_set_specifications';

const EditTimesheetForm = (props) => {
  const [possible_projects, set_possible_projects] = useState([]);
  const [possible_stages, set_possible_stages] = useState([]);
  const [possible_meeting_tasks, set_possible_meeting_tasks] = useState([]);
  const [possible_work_sites, set_possible_work_sites] = useState([]);

  useEffect(() => {
    const active_project = props.values.project_id;
    const active_stage = props.values.stage_id;

    listPossibleSpecificationProjects().then(({ response }) => {
      set_possible_projects(response.data);

      // props.change('project_id', active_project);
    });

    if (active_project) {
      listPossibleSpecificationStages(active_project).then(({ response }) => {
        set_possible_stages(response.data);

        // props.change('stage_id', active_stage);
      });
    }

    if (active_stage) {
      listPossibleMeetingTasks(active_stage).then(({ response }) => {
        set_possible_meeting_tasks(response.data);
      });
    }

    listPossibleWorkSites().then(({ response }) => {
      set_possible_work_sites(response.data);
    });
  }, []);

  const _handleSubmit = (edit) => {
    props.change('edit', edit);

    setTimeout(() => props.submit(), 10);
  };

  const possibleMinutes = [
    { value: '0', label: '00' },
    { value: 15, label: '15' },
    { value: 30, label: '30' },
    { value: 45, label: '45' },
  ];

  const { values } = props;

  return (
    <Form className={styles.wrapper}>
      <div className={styles.form}>
        <div className={styles.flex}></div>
        <div className={styles.flex}>
          <div className={styles.f2}>
            <Field
              name='date'
              component={DateTimePicker}
              label={__('table.columns.timesheet-date-time')}
              footer
            />
          </div>
          <div className={styles.f4} />
        </div>
        <div className={styles.flex}>
          <div className={styles.f1}>
            <Field
              name='hours'
              component={Input}
              label={__('table.columns.timesheet-hours')}
              numeric
              maxLength={4}
              hint={__('timesheets.hint.in-hours')}
            />
          </div>
          <div className={styles.f1}>
            <Field
              name='minutes'
              component={Select}
              searchable
              options={possibleMinutes}
              label={__('table.columns.timesheet-minutes')}
            />
          </div>
          <div className={styles.f2} />
        </div>
        <div className={styles.flex}>
          <div className={styles.f1}>
            <Field
              name='project_id'
              searchable={true}
              component={Select}
              label={__('edit-timesheet-form.project')}
              disabled={true}
              options={_.map(possible_projects, (project) => ({
                value: project.id,
                label: [project.code, project.name].join(': '),
              }))}
            />
          </div>
          <div className={styles.f1}>
            <Field
              name='stage_id'
              // disabled={!values.project_id}
              searchable={true}
              component={Select}
              label={__('edit-timesheet-form.stage')}
              disabled={true}
              options={_.map(possible_stages, (stage) => ({
                value: stage.id,
                label: [_.padStart(stage.order, 2, '0'), stage.name].join(': '),
              }))}
            />
          </div>
        </div>
        <Field
          name='task_id'
          searchable={true}
          component={Select}
          label={__('edit-timesheet-form.task')}
          disabled={true}
          options={_.map(possible_meeting_tasks, (task) => ({
            value: task.id,
            label: ['T-', task.identifier, ': ', task.title],
          }))}
        />
        <Field
          name='work_site_id'
          searchable={true}
          component={Select}
          label={__('edit-timesheet-form.work-site')}
          options={_.map(possible_work_sites, (workSite) => ({
            value: workSite.id,
            label: workSite.name,
          }))}
        />
        <Field
          name='description'
          component={Textarea}
          label={__('edit-timesheet-form.description')}
          maxLength={200}
          rows={1}
          maxRows={5}
        />
        <div className={styles.flex}>
          <Field
            name='is_billable'
            component={Checkbox}
            label={__('edit-timesheet-form.nonbillable')}
            className={styles.checkbox}
            onChange={(event) => {}}
          />
          <Field
            name='is_overtime'
            component={Checkbox}
            label={__('edit-timesheet-form.not_overtime')}
            className={styles.checkbox}
            onChange={(event) => {}}
          />
        </div>

        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={props.actions.hideEditTimesheetWizard}
          />
          <ButtonGroup>
            <Button
              lightBlue
              medium
              middleText={__('button.edit')}
              spinning={props.submitting}
              onClick={() => _handleSubmit(false)}
            />
            {/* {this.props.match.path == '/stages/:stage/tasks' && (
                <Button
                  lightBlue
                  medium
                  middleText={__('button.create-edit')}
                  spinning={this.props.submitting}
                  onClick={() => this._handleSubmit(true)}
                />
              )} */}
          </ButtonGroup>
        </div>
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    values: getFormValues('edit_timesheet_wizard.edit_timesheet')(state),
    active_modules: state.general.active_modules,
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      hideEditTimesheetWizard: () =>
        dispatch({
          type: 'table.timesheets/SET_TABLE_PARAMS',
          params: {
            wizard_timesheet_edit: undefined,
            task_id: undefined,
            time_entries: undefined,
          },
        }),
    },
  };
};

export default withRouter(
  reduxForm({
    form: 'edit_timesheet_wizard.edit_timesheet',
  })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(subscribe(Localization, 'localization')(EditTimesheetForm))
  )
);
