import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/EditTimesheetWizard.scss';
import * as _ from 'lodash';
import EditTimesheetForm from '../forms/edit_timesheet_wizard/EditTimesheetForm';
import { withRouter } from 'react-router';
import {
  __,
  getActiveProject,
  getActiveStage,
  setFormErrors,
  setTableParams,
  validateForm,
  convertHoursAndMinutesToMinutes,
  convertMinutesToHoursAndMinutes,
} from '../core/utils';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';
import { connect } from 'react-redux';
import AddTimesheetIcon from '../assets/images/16pix AddTime.svg';
import {
  createTimesheet,
  editTimesheet,
  listTimesheets,
} from '../redux/actions/table/timesheets';
import { setNotification } from '../redux/actions/general/notification';
import { readTask } from '../redux/actions/table/all_tasks';

const EditTimesheetWizard = function (props) {
  const _handleSubmit = (formData) => {
    const id = props.time_sheet_row?.id;
    const task_id = props.task_id;

    formData = {
      ...formData,
      user_id: props.auth.id,
      minutes: convertHoursAndMinutesToMinutes(
        formData?.hours,
        formData?.minutes
      ),
    };

    return validateForm(
      editTimesheet(id, formData).then(({ response }) => {
        readTask(task_id);

        setNotification(__('wizard.edit-timesheet.confirm'));

        props.onClose();
      })
    );
  };

  const active_project = getActiveProject();
  const active_stage = getActiveStage();

  const { hours, minutes } = convertMinutesToHoursAndMinutes(
    props.time_sheet_row?.minutes
  );

  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <span>{__('wizard.edit-timesheet.title.edit')}</span>
          <span>{__('wizard.edit-timesheet.subtitle.edit')}</span>
        </div>
        <div className={styles.right}>
          <AddTimesheetIcon
            style={{ fill: 'white !important', color: 'white !important' }}
          />
        </div>
      </div>
      <div className={styles.body}>
        <EditTimesheetForm
          onSubmit={_handleSubmit}
          initialValues={{
            project_id: active_project,
            stage_id: active_stage,
            is_billable: 1,
            is_overtime: 0,
            task_id: props.task_id,
            ..._.pick(props.time_sheet_row, [
              'description',
              'work_site_id',
              'is_billable',
              'is_overtime',
              'date',
              'id',
            ]),
            minutes: minutes,
            hours: hours,
          }}
        />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    table: state.table['timesheets'],
    auth: state.auth,
    task_id: state.table['timesheets'].task_id,
    time_sheet_row: state.table['timesheets'].time_sheet_row,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(subscribe(Localization, 'localization')(EditTimesheetWizard))
);
