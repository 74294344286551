import React, { useState, useEffect } from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/tasks/Timesheets.scss';
import {
  __,
  convertMinutesToHoursAndMinutes,
  dateFrom,
  //mapStateToProps,
  scrollToBottom,
} from '../../core/utils';
import { subscribe } from 'react-contextual';
import { connect } from 'react-redux';
import { setNotification } from '../../redux/actions/general/notification';
import {
  addTaskChecklistItem,
  deleteTaskChecklistItem,
  reorderTaskChecklistItems,
  updateTaskChecklistItemCompletion,
  updateTaskChecklistItemTitle,
} from '../../redux/actions/table/all_tasks';
import autobind from 'autobind-decorator';
import UncheckedIcon from '../../assets/images/unchecked-15x15.svg';
import CheckedIcon from '../../assets/images/checked-15x15.svg';
import * as _ from 'lodash';
import Tooltip from '../../components/Tooltip';
import classNames from 'classnames';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import EditIcon from '../../assets/images/edit-16x16.svg';
import { ReactSortable } from 'react-sortablejs';
import GrabberIcon from '../../assets/images/grabber-16x16.svg';
import Localization from '../../helpers/Localization';
import Avatar from '../../components/Avatar';

const Timesheets = (props) => {
  const { collapsed, data } = props;
  const { is_editor } = data;

  return (
    <div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
      {!_.isEmpty(data.time_entries) &&
        is_editor &&
        !_.includes(['closed', 'canceled'], data.status) && (
          <div className={styles.heading}>
            <span className={styles.order}>#</span>
            <span className={styles.description}>
              {__('tasks.all-tasks.flyout.add-timesheet-item.description')}
            </span>
            <span className={styles.user}>
              {__('tasks.all-tasks.flyout.add-timesheet-item.user')}
            </span>
            <span className={styles.date}>
              {__('tasks.all-tasks.flyout.add-timesheet-item.date')}
            </span>
            <span className={styles.minutes}>
              {__('tasks.all-tasks.flyout.add-timesheet-item.minutes')}
            </span>
            {is_editor && !_.includes(['closed', 'canceled'], data.status) && (
              <span className={styles.edit} />
            )}
          </div>
        )}
      <div className={styles.scroller}>
        {_.isEmpty(data.time_entries) ? (
          <div className={styles.empty}>
            {__('meetings.tasks.flyout.there-are-no-checklist-items')}
          </div>
        ) : (
          _.map(data.time_entries, (row, i) => {
            const duration = convertMinutesToHoursAndMinutes(row.minutes, true);

            return (
              <div
                className={classNames(
                  styles.row,
                  row.completed_at && styles.blue
                )}
                key={row.id}
              >
                <span className={styles.order}>{i}</span>
                <span className={styles.description}>{row.description}</span>
                <span className={styles.user}>
                  <Avatar
                    avatar_url={row.user.avatar_url}
                    name={row.user.fullname}
                    active={row.user.active}
                  />
                </span>
                <span className={styles.date}>{dateFrom(row.date)}</span>
                <span className={styles.minutes}>{duration}</span>
                {is_editor &&
                  !_.includes(['closed', 'canceled'], data.status) &&
                  (props.auth.id === row.user.id ? (
                    <span
                      className={styles.edit}
                      onClick={() =>
                        props.actions.setTableParams({
                          wizard_timesheet_edit: true,
                          task_id: data.id,
                          time_sheet_row: row,
                        })
                      }
                    >
                      <Tooltip
                        text={__('projects.tooltips.tasks.edit-timesheet')}
                      >
                        <EditIcon />
                      </Tooltip>
                    </span>
                  ) : (
                    <span className={styles.emptyEdit} />
                  ))}
              </div>
            );
          })
        )}
      </div>
      {is_editor && !_.includes(['closed', 'canceled'], data.status) && (
        <div className={styles.addItem}>
          <button
            onClick={() =>
              props.actions.setTableParams({
                //wizard_active: true,
                wizard_timesheet_add: true,
                task_id: data.id,
              })
            }
          >
            {__('tasks.notification.timesheets.add')}
          </button>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.timesheets/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(subscribe(Localization, 'localization')(Timesheets));
