import React from 'react';
import * as _ from 'lodash';
import { ContextMenuTrigger } from 'react-contextmenu';

const Row = ({
  index,
  id,
  level,
  children,
  style,
  firstChild,
  className,
  unallocatedGroupCollapsed,
  parentId,
  name,
  code,
  setSelectedItemContext,
  specification_code_id,
  boq_calculation_id,
  ...rest
}) => {
  const component = (
    <div {...rest} style={style} className={className}>
      {children}
    </div>
  );

  if (id == 'UN' || level == 2) {
    return component;
  }

  return (
    <ContextMenuTrigger
      id='specification_set_items_context_menu'
      holdToDisplay={-1}
      collect={(props) => {
        return setSelectedItemContext({
          name,
          level,
          id,
          specification_code_id,
          boq_calculation_id,
        });
      }}
    >
      {component}
    </ContextMenuTrigger>
  );
};

export default Row;
