import * as React from 'react';
import { Component } from 'react';
import Table from '../../components/Table';
import { connect } from 'react-redux';
import { createSidebar } from '../../redux/actions/general/sidebar';
import { __, dateFrom, redirect, setURLParam } from '../../core/utils';
import { listActiveModules } from '../../redux/actions/general/active_modules';
import * as _ from 'lodash';
import { listAllSharedModels } from '../../redux/actions/table/all_shared_models';
import TableNA from '../../components/TableNA';
import Avatar from '../../components/Avatar';
import styles from '../../styles/views/shared_with_me/SharedWithMe.scss';
import Tooltip from '../../components/Tooltip';

const mapStateToProps = (state) => {
  return {
    table: state.table['all_shared_models'],
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.all_shared_models/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class AllSharedModels extends Component {
  componentDidMount() {
    createSidebar({
      title: __('submenu.title.all-shared-models'),
      items: [
        {
          title: __('submenu.title.all-shared-models'),
          link: () => '/shared_with_me',
          active: true,
        },
      ],
    });
  }

  render() {
    const columns = [
      {
        key: 'project',
        name: __('table.all-shared-models.columns.project'),
        required: true,
        default: true,
        value: (value, row) => value,
      },
      {
        key: 'stage',
        name: __('table.all-shared-models..columns.stage'),
        required: true,
        default: true,
        value: (value, row) => value,
      },

      {
        key: 'model_type',
        name: __('table.all-shared-models.columns.model_type'),
        required: true,
        default: true,
        value: (value, row) => {
          return value;
        },
      },
      {
        key: 'name',
        name: __('table.all-shared-models.columns.name'),
        required: true,
        default: true,
        value: (value, row) => {
          return row.identifier + ': ' + value;
        },
      },
      {
        key: 'company_name',
        name: __('table.all-shared-models.columns.company_name'),
        required: true,
        default: true,
        value: (value, row) => value,
      },

      {
        key: 'created_by',
        name: __('table.all-shared-models.columns.created_by'),
        value: (user) => (
          <Avatar
            avatar_url={user.avatar_url}
            name={user.fullname}
            active={user.active}
          />
        ),
        width: 50,
      },

      {
        key: 'members',
        name: __('table.all-shared-models.columns.members'),
        value: (members) => (
          <div className={styles.members}>
            {_.size(members) == 0 && <TableNA />}
            {_.size(members) == 1 &&
              _.map(_.take(members, 1), (user, i) => (
                <Avatar key={i} {...user} />
              ))}
            {_.size(members) > 1 && (
              <Tooltip text={_.map(members, 'name').join('\n')}>
                <div className={styles.more}>
                  <span>{_.size(members)}</span>
                </div>
              </Tooltip>
            )}
          </div>
        ),
        sortable: false,
        width: 50,
        mobileVisible: true,
      },

      {
        key: 'created_at',
        name: __('table.all-shared-models.columns.created_at'),
        value: (value) => (value ? dateFrom(value) : <TableNA />),
        width: 80,
        active: false,
      },
    ];

    // const gridOptions = {
    //   section: __('submenu.title.all-projects'),
    //   image: (id, row) => row.image_url,
    //   title: (row) => row.name,
    //   subTitle: (row) => row.description,
    //   content: (row) => [
    //     'created ' + dateFrom(row.created_at) + ' by ' + row.company,
    //   ],
    // };

    return (
      <Table
        listAction={() => listAllSharedModels()}
        onRowClick={(id, row) => {
          redirect(row.url);
        }}
        name='all_shared_models'
        title={__('submenu.title.all-shared-models')}
        columns={columns}
        onLoad={() => listActiveModules()}
      />
    );
  }
}

export default AllSharedModels;
